import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (route, request) {
    return axios.get(`/api/${route}/search`, { params: request });
  },
  find: function (id) {
    return axios.get(`/api/investmentRussia/find?id=${id}`);
  },
  downloadFile: function (route, file) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`/api/${route}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  uploadInvestmentsFile: function (year, file) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`/api/investment/upload/${year}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  uploadFinancialSector: function (indicator, file) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`/api/financialsector/upload/${indicator}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  uploadFavorTradeFile: function (year, isExport, reportQuartal, file) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`/api/favortrade/uploadforyear/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        isExport,
        reportYear: year,
        reportQuartal,
      },
    });
  },
  uploadForeignDirectInvestment: function (isStock, file) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`/api/foreigndirectinvestment/uploaddata/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        isStock,
      },
    });
  },
  async mappingCreate(isActive, countryId, countrySourceName) {
    return await axios.post(`/api/countrymapping/create`, {
      isActive,
      countryId,
      countrySourceName,
    });
  },
  uploadDirect: function (route, file) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`/api/${route}/uploadDirect`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  exportExcel: function (request) {
    return Utils.downloadFile('/api/exportPotential/exportExcel', 'exportpotential_registry.xlsx', request);
  },
  refresh: function (route) {
    return axios.get(`/api/${route}/refresh`, {});
  },
  uploadFile: function (route, file, countryId, tradingOperationId, tradeItemTypeId) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('countryId', countryId);
    formData.append('tradingOperationId', tradingOperationId);
    formData.append('tradeItemTypeId', tradeItemTypeId);
    return axios.post(`/api/${route}/uploaddata`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getStriTypeList: function () {
    return axios.get(`/api/striindex/getStriTypeList`);
  },
  getTecWorldYears: function () {
    return axios.get(`/api/tecworld/yearList`);
  },
  importCsv: function (name) {
    return axios.get(`/api/exportProduct/importCsv/${name}`);
  },
  calculateSummary: function () {
    return axios.get(`/api/exportProduct/calculateSummary`);
  },
  getUnfriendlyCountriesTemplate: function () {
    return Utils.downloadFile('/api/unfriendlyсountries/getuploadtemplate', 'unfriendly_template', {});
  },
};
